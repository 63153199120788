import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { GenericService } from "src/app/controller/services/generic.service";
import { MsgService } from "src/app/controller/services/msg.service";
import { Mensagens } from "../../shared/components/mensagens-ptbr";
import { PesquisaInfoComponent } from "../pesquisa-info/pesquisa-info.component";

@Component({
  selector: "app-pesquisa-detalhe",
  templateUrl: "./pesquisa-detalhe.component.html",
  styleUrls: ["./pesquisa-detalhe.component.scss"],
})
export class PesquisaDetalheComponent implements OnInit {
//enum de situacao do cbi
  MessageEnum = {
    0: "Pesquisa não encontrada ou em análise.",
    1: "Pesquisa em andamento.",
    2: "Nada consta.",
    3: "Consta - Detalhamento das informações.",
    4: "Apenas observações - Detalhamento das informações.",
    5: "Dados Incompletos.",
    6: "Outros.",
  };

  SituacaoEnum = {
    0: "Em Andamento",
    1: "Em Andamento",
    2: "Finalizada",
    3: "Finalizada",
    4: "Finalizada",
    5: "Dados Incompletos",
    6: "Finalizada",
  };

  //comunicação entre componetes
  @ViewChild(PesquisaInfoComponent) componetChild;
  @Input() item: Array<any>;
  @Output() eventOutput = new EventEmitter();

  //formulário e elementos
  detailForm: FormGroup;
  itensAPI: any;
  itemCPF: string;
  itemNome: string;
  itemRG: string;
  protocolo: string;
  urlBook: string;
  urlUniqueSearch: string;
  token: string;
  itemComplete: number;
  CBICode: number;
  CBIProtocol: string;

  //boaVista tabelas
  boaVistaInfo: any;

  //booleans
  isDetail: boolean;
  isConsulta: boolean;
  isLoadingButton: boolean;
  isConfirmation: boolean = false ;

  //checkboxes
  selectedNatcorp: boolean;
  selectedEscavador: boolean;
  selectedBoavista: boolean;
  selectedCbi: boolean;

  //switchs
  switchNacorp: boolean;
  switchEscavador: boolean;
  switchBoaVista: boolean;
  switchCBI: boolean;

  //histórico
  historicoNacorp: boolean;
  historicoEscavador: boolean;
  historicoBoaVista: boolean;
  historicoCBI: boolean;

  //table
  cols = [];
  itensSelectedData: Array<{ nome: string; objeto: any }> = [];

  //inscrição
  subscription: Subscription = new Subscription();

  constructor(
    private genericService: GenericService,
    private msgService: MsgService
  ) { }

  //função que inicia o componente
  ngOnInit() {
    this.setParamsTable(localStorage.getItem("language"));
    this.cols = [{ field: "nome", width: "100%" }];
    this.initChecks();
    this.token = localStorage.getItem("@pesquisa:token");
  }

  //função que define os parâmetros para a criação da tabela
  setParamsTable(idioma) {
    this.cols = [{ field: "nome", width: "100%", align: "center" }];
  }

  //função que adiciona em lista para apresentação de informação detalhadas das APIs
  selectAPISDetail(item) {
    this.itensSelectedData.push({ nome: item, objeto: item });
  }

  //função que seta os parâmetros para edição
  setFormDetail(item) {
    this.item = item;
    this.itemNome = item?.name;
    this.itemCPF = item?.document;
    this.itemRG = item?.registry;
    this.itemComplete = item?.is_incomplete;
    this.consultarAPIS(this.itemCPF);
  }

  //função que retorna para listagem da tabela
  voltarListagem() {
    this.isDetail = false;
    this.initChecks();
    this.eventOutput.emit();
  }

  //função que inicia os checkboxes
  initChecks() {
    this.selectedNatcorp = false;
    this.selectedEscavador = false;
    this.selectedBoavista = false;
    this.selectedCbi = false;

    this.switchNacorp = false;
    this.switchEscavador = false;
    this.switchBoaVista = false;
    this.switchCBI = false;
  }

  //função que consulta os itens selecionados
  consultar() {
    this.isLoadingButton = true;
    this.itensSelectedData = [];

    if (this.selectedNatcorp) {
      this.selectAPISDetail("natcorp");
      let consulta = {
        document: this.itemCPF,
        novaConsulta: this.validateSearch(
          this.historicoNacorp,
          this.switchNacorp
        ),
      };

      this.consultarNatcorp(consulta);
    }

    if (this.selectedEscavador) {
      this.selectAPISDetail("escavador");
      let consulta = {
        document: this.itemCPF,
        nome: this.itemNome,
        novaConsulta: this.validateSearch(
          this.historicoEscavador,
          this.switchEscavador
        ),
      };
      this.consultarEscavador(consulta);
    }

    if (this.selectedBoavista) {
      this.selectAPISDetail("boavista");
      let consulta = {
        document: this.itemCPF,
        novaConsulta: this.validateSearch(
          this.historicoBoaVista,
          this.switchBoaVista
        ),
      };
      this.consultarBoaVista(consulta);
    }

    if (this.selectedCbi) {
      this.selectAPISDetail("cbi");
      let consulta = {
        document: this.itemCPF,
        novaConsulta: this.validateSearch(this.historicoCBI, this.switchCBI),
      };
      this.consultarCBI(consulta);
    }

    setTimeout(() => {
      this.isConsulta = true;
      this.isDetail = false;
      this.initChecks();
      this.isLoadingButton = false;
    }, 2000);
  }

  //função que valida se existe registro e se é uma nova busca
  validateSearch(historico, novaConsulta) {
    if (historico) {
      if (historico && novaConsulta) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  //função que consulta API Natcorp
  consultarNatcorp(item) {
    this.genericService.tipoUrl("natcorp/candidato/situacao", item);

    let natCorpCandidatoSub$ = this.genericService.get().subscribe(
      (resp) => {
        let newItem = this.itensSelectedData.findIndex(
          (i) => i.nome === "natcorp"
        );
        this.itensSelectedData[newItem].objeto = resp.body;
        /* this.itensSelectedData.push({ nome: 'natcorp', objeto: resp.body[0] }); */
      },
      (error) => {
        this.isLoadingButton = false;
        this.msgService.getMessage(
          "warn",
          Mensagens.ConsultaErroSummary,
          Mensagens.ConsultaErroDetail
        );
      }
    );
    this.subscription.add(natCorpCandidatoSub$);
  }

  //função que consulta API Escavador
  consultarEscavador(item) {
    this.genericService.tipoUrl("escavador/candidato/situacao", item);

    let escavadorCandidatoSub$ = this.genericService.get().subscribe(
      (resp) => {
        let newItem = this.itensSelectedData.findIndex(
          (i) => i.nome === "escavador"
        );
        this.itensSelectedData[newItem].objeto = resp.body;
      },
      (error) => {
        this.isLoadingButton = false;
        this.msgService.getMessage(
          "warn",
          Mensagens.ConsultaErroSummary,
          Mensagens.ConsultaErroDetail
        );
      }
    );
    this.subscription.add(escavadorCandidatoSub$);
  }

  //função que consulta API BoaVista
  consultarBoaVista(item) {
    this.genericService.tipoUrl("boaVista/consulta", item);

    let boaVistaCandidatoSub$ = this.genericService.get().subscribe(
      (resp) => {
        let newItem = this.itensSelectedData.findIndex(
          (i) => i.nome === "boavista"
        );
        this.itensSelectedData[newItem].objeto = resp.body;
        this.boaVistaInfo = resp.body;
        this.componetChild.getTableInfo(this.boaVistaInfo);
        this.infoButtonValid();
        
      },
      (error) => {
        this.isLoadingButton = false;
        this.msgService.getMessage(
          "warn",
          Mensagens.ConsultaErroSummary,
          Mensagens.ConsultaErroDetail
        );
      }
    );
    this.subscription.add(boaVistaCandidatoSub$);

  }

  //função que consulta API CBI
  consultarCBI(item) {
    this.genericService.tipoUrl("CBI/cadastroPesquisa", item);

    let cbiCandidatoSub$ = this.genericService.get().subscribe(
      (resp) => {
        let newItem = this.itensSelectedData.findIndex((i) => i.nome === "cbi");
        this.itensSelectedData[newItem].objeto = resp.body[0];
        this.protocolo = resp.body[0].protocol;
      },
      (error) => {
        this.isLoadingButton = false;
        this.msgService.getMessage(
          "warn",
          Mensagens.ConsultaErroSummary,
          Mensagens.ConsultaErroDetail
        );
      }
    );
    this.subscription.add(cbiCandidatoSub$);
  }

  //função que consulta os itens selecionados
  consultarAPIS(item) {
    this.genericService.tipoUrl("retornaPesquisas", item);

    let retornaPesquisasSub$ = this.genericService.getById().subscribe(
      (resp) => {
        this.itensAPI = resp.body;
        this.validaHistorico();
      },
      (error) => {
        this.msgService.getMessage(
          "warn",
          Mensagens.ConsultaErroSummary,
          Mensagens.ConsultaErroDetail
        );
      }
    );
    this.subscription.add(retornaPesquisasSub$);
  }

  //função para verificar apis que possuem histórico
  validaHistorico() {
    const natcorp = this.itensAPI.find((i) => i?.type === "SEARCH_NATCORP");
    if (natcorp !== undefined) {
      this.historicoNacorp = true;
    } else {
      this.historicoNacorp = false;
    }

    const escavador = this.itensAPI.find((i) => i?.type === "SEARCH_ESCAVADOR");
    if (escavador !== undefined) {
      this.historicoEscavador = true;
    } else {
      this.historicoEscavador = false;
    }

    const boavista = this.itensAPI.find((i) => i?.type === "SEARCH_BOAVISTA");
    if (boavista !== undefined) {
      this.historicoBoaVista = true;
    } else {
      this.historicoBoaVista = false;
    }

    const cbi = this.itensAPI.find((i) => i?.type === "SEARCH_CBI");
    if (cbi !== undefined) {
      this.historicoCBI = true;
    } else {
      this.historicoCBI = false;
    }
  }

  //função que define os parametros de exibição para o elemento filho
  getInfo() {
    this.componetChild.setInfoDisplay();
  }

  //função que atualiza as informações do CBI
  updateCBI() {
    let item = {
      protocolo: this.protocolo,
      cpf: this.itemCPF,
    };
    this.genericService.tipoUrl("CBI/getResultado", item);

    let cbiResultadosSub$ = this.genericService.get().subscribe(
      (resp) => {
        let newItem = this.itensSelectedData.findIndex((i) => i.nome === "cbi");
        this.itensSelectedData[newItem].objeto = resp.body[0];
        this.protocolo = resp.body[0].protocol;
        this.msgService.getMessage(
          "success",
          Mensagens.ConsultaSummary,
          Mensagens.ConsultaDetail
        );
      },
      (error) => {
        this.msgService.getMessage(
          "warn",
          Mensagens.ConsultaErroSummary,
          Mensagens.ConsultaErroDetail
        );
      }
    );
    this.subscription.add(cbiResultadosSub$);
  }

  //função que retorna o book do candidato
  getDownloadBook() {

    this.urlBook = `/${this.itemCPF}/pdf/${this.token}/book_completo`;
    this.openUrl(this.genericService.getPDF(this.urlBook));
  }

  //função que retorna o tipo de pdf selecionado
  getUniqueSearchPdf(tipoPesquisa: string) {

    this.urlBook = `/unica/${this.itemCPF}/pdf/${this.token}/${tipoPesquisa}`;
    this.openUrl(this.genericService.getPDF(this.urlBook));
  }

  //função que define a exibição do botão info de acordo com as tabelas retornadas
  infoButtonValid(): boolean {
    if (
      this.boaVistaInfo?.search_boavista_blocked_check?.length > 0 ||
      this.boaVistaInfo?.search_boavista_civil_lawsuit?.length > 0 ||
      this.boaVistaInfo?.search_boavista_debit?.length > 0 ||
      this.boaVistaInfo?.search_boavista_dishonored_check?.length > 0 ||
      this.boaVistaInfo?.search_boavista_informed_return?.length > 0 ||
      this.boaVistaInfo?.search_boavista_judicial_recovery?.length > 0 ||
      this.boaVistaInfo?.search_boavista_participation?.length > 0 ||
      this.boaVistaInfo?.search_boavista_protest?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  //função que abre a url passada por parâmetro
  openUrl(item) {
    window.open(item, "_blank");
  }

  openModalCBI(){
    const cbi = this.itensAPI.find((i) => i?.type === "SEARCH_CBI");
      this.CBICode = cbi.code;
      this.CBIProtocol = cbi.protocol;
    if(cbi.code != null && this.switchCBI){
      this.isConfirmation = true;
      
    }
  }

  ajustarRadio(bool:boolean){
    this.switchCBI = bool;
    this.isConfirmation = false;
  }

  //responsável que encerrar conexão de um componente
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
