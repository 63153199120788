import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { MsgService } from './msg.service';

@Injectable({
  providedIn: 'root'
})
export class HandleErrosService {

  constructor(private msgService: MsgService) { }

  handleError(error: HttpErrorResponse) {
    this.msgService.getMessage('error', error.error.error.msg.summary, error.error.error.msg.detail);
    return throwError(error);
  }

  handleErrorLogin(error: HttpErrorResponse) {
    this.msgService.getMessage('warn', error.error.error.msg.summary, error.error.error.msg.detail);
    return throwError(error);
  }

  //função que captura erro com status 500
  check500Error(error) {
    switch (error.status) {
      case 500:
        this.msgService.getMessage('error', error.error.error.msg.summary, error.error.error.msg.detail);
        break;
    }
  }

}
