import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

type Tabela = {
  [key: string]: any;
  // general: any[];
  // search_boavista_debit : any[],
  // search_boavista_civil_lawsuit: any[],
  // search_boavista_dishonored_check: any[],
  // search_boavista_informed_return: any[],
  // search_boavista_blocked_check: any[],
  // search_boavista_participation: any[],
  // search_boavista_protest: any[],
  // search_boavista_judicial_recovery: any[]
}

@Component({
  selector: 'app-pesquisa-info',
  templateUrl: './pesquisa-info.component.html',
  styleUrls: ['./pesquisa-info.component.scss']
})

export class PesquisaInfoComponent implements OnInit {

  

  //comunicação entre componetes
  @Input() item: Tabela;
  @Output() eventOutput = new EventEmitter;

  //modelo
  search_boavista_blocked_check: any[] = [];
  search_boavista_civil_lawsuit: any[] = [];
  search_boavista_debit: any[] = [];
  search_boavista_dishonored_check: any[] = [];
  search_boavista_informed_return: any[] = [];
  search_boavista_judicial_recovery: any[] = [];
  search_boavista_participation: any[] = [];
  search_boavista_protest: any[] = [];

  porco:boolean = false;

  //booleans
  displayInfo: boolean;

  //tabela
  cols: any[];
  cols2: any[];
  cols3: any[];
  cols4: any[];
  cols5: any[];
  cols6: any[];
  cols7: any[];
  cols8: any[];

  //idiomas
  language: string;

  constructor(
    public translateService: TranslocoService,
  ) { }

  //função que inicia o componente
  ngOnInit() {
    this.getActiveLanguage();
    this.setParamsTable();
    this.getTableInfo();
  }

  //função que retorna o idioma ativo
  getActiveLanguage() {
    this.language = this.translateService.getActiveLang();
  }

  //função que define os parâmetros para a criação da tabela
  setParamsTable() {
    this.cols = [
      { field: 'agency', header: 'Agência', align: 'left' },
      { field: 'amount', header: 'Quantia', align: 'left' },
      { field: 'availability_date', header: 'Data de disponibilidade', align: 'left' },
      { field: 'bank', header: 'Banco', align: 'left' },
      { field: 'currency', header: 'Ocorrência', align: 'left' },
      { field: 'document', header: 'Documento', align: 'left' },
      { field: 'document_type', header: 'Tipo documento', align: 'left' },
      { field: 'final_check', header: 'Checagem final', align: 'left' },
      { field: 'informer', header: 'Cidade', align: 'left' },
      { field: 'initial_check', header: 'Cheque', align: 'left' },
      { field: 'occurrence_date', header: 'Data ocorrência', align: 'left' },
      { field: 'paragraph', header: 'Parágrafo', align: 'left' },
      { field: 'transaction_account', header: 'Conta', align: 'left' },
    ];

    this.cols2 = [
      { field: 'amount', header: 'Quantia', align: 'left' },
      { field: 'city_code', header: 'Código da cidade', align: 'left' },
      { field: 'civil_court', header: 'Corte civil', align: 'left' },
      { field: 'civil_lawsuit', header: 'Processo Civil', align: 'left' },
      { field: 'distribution_date', header: 'Data distribuição', align: 'left' },
      { field: 'lawsuit', header: 'Processo', align: 'left' },
      { field: 'square', header: 'Bloco', align: 'left' },
      { field: 'state_acronym', header: 'UF', align: 'left' },
      { field: 'writer', header: 'Autor', align: 'left' },
    ];

    this.cols3 = [
      { field: 'amount', header: 'Quantidade', align: 'left' },
      { field: 'availability_date', header: 'Data de disponibilidade', align: 'left' },
      { field: 'city', header: 'Cidadão', align: 'left' },
      { field: 'contract', header: 'Contrato', align: 'left' },
      { field: 'currency', header: 'Moeda', align: 'left' },
      { field: 'informed_consultant', header: 'Consultor informado', align: 'left' },
      { field: 'informer', header: 'Informante', align: 'left' },
      { field: 'occurrence_date', header: 'Data de ocorrência', align: 'left' },
      { field: 'occurrence_type', header: 'Tipo de ocorrência', align: 'left' },
      { field: 'situation', header: 'Situação', align: 'left' },
      { field: 'state_acronym', header: 'UF', align: 'left' },
      { field: 'status', header: 'Condição', align: 'left' },
      { field: 'status_city', header: 'Condição município', align: 'left' },
    ];

    this.cols4 = [
      { field: 'agency', header: 'Agência', align: 'left' },
      { field: 'bank', header: 'Banco', align: 'left' },
      { field: 'bank_name', header: 'Nome do Banco', align: 'left' },
      { field: 'document', header: 'Documento', align: 'left' },
      { field: 'document_type', header: 'Tipo de documento', align: 'left' },
      { field: 'last_occurrence_12_date', header: 'Última ocorrência 12', align: 'left' },
      { field: 'last_occurrence_13_date', header: 'Última ocorrência 13', align: 'left' },
      { field: 'last_occurrence_14_date', header: 'Última ocorrência 14', align: 'left' },
      { field: 'last_occurrence_99_date', header: 'Última ocorrência 99', align: 'left' },
      { field: 'name', header: 'Nome', align: 'left' },
      { field: 'reason_12', header: 'Razão 12', align: 'left' },
      { field: 'reason_13', header: 'Razão 13', align: 'left' },
      { field: 'reason_14', header: 'Razão 13', align: 'left' },
      { field: 'reason_99', header: 'Razão 99', align: 'left' },
    ];

    this.cols5 = [
      { field: 'agency', header: 'Agência', align: 'left' },
      { field: 'amount', header: 'Quantia', align: 'left' },
      { field: 'bank', header: 'Banco', align: 'left' },
      { field: 'city', header: 'Cidade', align: 'left' },
      { field: 'currency', header: 'Moeda', align: 'left' },
      { field: 'document', header: 'Documento', align: 'left' },
      { field: 'document_type', header: 'Tipo de documento', align: 'left' },
      { field: 'final_check', header: 'Checagem final', align: 'left' },
      { field: 'informer', header: 'informante', align: 'left' },
      { field: 'informer_code', header: 'Código do informante', align: 'left' },
      { field: 'initial_check', header: 'Verificação inicial', align: 'left' },
      { field: 'occurrence_date', header: 'Data de ocorrência', align: 'left' },
      { field: 'paragraph', header: 'Parágrafo', align: 'left' },
      { field: 'reason', header: 'Razão', align: 'left' },
      { field: 'registry_date', header: 'Data de registro', align: 'left' },
      { field: 'state_acronym', header: 'UF', align: 'left' },
      { field: 'transaction_account', header: 'Conta de transação', align: 'left' },
    ]

    this.cols6 = [
      { field: 'city_code', header: 'Cod cidade', align: 'left' },
      { field: 'corporate_name', header: 'Nome corporativo', align: 'left' },
      { field: 'document', header: 'Documento', align: 'left' },
      { field: 'document_type', header: 'Tipo documento', align: 'left' },
      { field: 'notary_office', header: 'Cartótio', align: 'left' },
      { field: 'occurrence_date', header: 'Data de ocorrência', align: 'left' },
      { field: 'occurrence_type', header: 'Tipo de ocorrência', align: 'left' },
      { field: 'square', header: 'Bloco', align: 'left' },
      { field: 'state_acronym', header: 'UF', align: 'left' },
    ];

    this.cols7 = [
      { field: 'amount', header: 'Quantia', align: 'left' },
      { field: 'corporate_name', header: 'Nome corporativo', align: 'left' },
      { field: 'currency_type', header: 'Tipo de moeda', align: 'left' },
      { field: 'document', header: 'Documento', align: 'left' },
      { field: 'document_type', header: 'Tipo de documento', align: 'left' },
      { field: 'entry_date', header: 'Data de entrada', align: 'left' },
      { field: 'note', header: 'Nota', align: 'left' },
      { field: 'occupation', header: 'Ocupação', align: 'left' },
      { field: 'partner_type', header: 'Tipo de parceiro', align: 'left' },
      { field: 'percentage_value', header: 'Valor percentual', align: 'left' },
      { field: 'second_document', header: '2° documento', align: 'left' },
      { field: 'second_document_type', header: '2° tipo de documento', align: 'left' },
    ];

    this.cols8 = [
      { field: 'amount', header: 'Quantia', align: 'left' },
      { field: 'city', header: 'Cidade', align: 'left' },
      { field: 'currency', header: 'Moeda', align: 'left' },
      { field: 'notary_office', header: 'Cartótio', align: 'left' },
      { field: 'occurrence_date', header: 'Data de ocorrência', align: 'left' },
      { field: 'occurrence_type', header: 'Tipo de ocorrência', align: 'left' },
      { field: 'state_acronym', header: 'UF', align: 'left' },
    ];

  }

  //função que consulta as informações cadastradas
  getTableInfo(updated?) {
    this.search_boavista_blocked_check = updated? updated.search_boavista_blocked_check : this.item?.search_boavista_blocked_check;
    this.search_boavista_civil_lawsuit = updated? updated.search_boavista_civil_lawsuit : this.item.search_boavista_civil_lawsuit;
    this.search_boavista_debit = updated? updated.search_boavista_debit : this.item.search_boavista_debit;
    this.search_boavista_dishonored_check = updated? updated.search_boavista_dishonored_check : this.item.search_boavista_dishonored_check;
    this.search_boavista_informed_return = updated? updated.search_boavista_informed_return : this.item.search_boavista_informed_return;
    this.search_boavista_judicial_recovery = updated? updated.search_boavista_judicial_recovery : this.item.search_boavista_judicial_recovery;
    this.search_boavista_participation = updated? updated.search_boavista_participation : this.item.search_boavista_participation;
    this.search_boavista_protest = updated? updated.search_boavista_protest : this.item.search_boavista_protest;    
  }



  //função que seta os parâmetros para adição
  setInfoDisplay() {
    this.displayInfo = true;
  }

  //função que cancela a interação e limpa o furmulário
  cancelar() {
    this.displayInfo = false;
  }

}