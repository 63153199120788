import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class CryptoService {
  //Chave para fazer e desfazer criptografia
  secretKey: string = 'q07FLvjBIkw7jh4yjwmutvOJJ0zul7Ga';

  constructor() {}

  //Função que criptografa o token para envio ao back-end
  encrypt(toEncrypt: string): string {
    return CryptoJS.AES.encrypt(toEncrypt, this.secretKey.trim()).toString().replace(/\+/g,'p1L2u3S').replace(/\//g,'s1L2a3S4h').replace(/=/g,'e1Q2u3A4l');
  }

  //Função que descriptografa o token recebido do back-end
  decrypt(toDecypt: string): string {
    return CryptoJS.AES.decrypt(toDecypt.replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '='), this.secretKey.trim()).toString(
      CryptoJS.enc.Utf8
    );
  }
}
