import { ActionReducerMap } from '@ngrx/store';

import * as fromAuth from '../app/view/shared/store/auth/auth.reducer';
import * as fromComponent from '../app/view/shared/store/component/component.reducer';
import * as fromLoading from '../app/view/shared/store/loading/loading.reducer';
import * as fromMessage from '../app/view/shared/store/message/message.reducer';
import * as fromUtil from '../app/view/shared/store/util/util.reducer';

export interface AppState {
	auth: fromAuth.AuthState;
	component: fromComponent.ComponentState;
	loading: fromLoading.LoadingState;
	message: fromMessage.MessageState;
	util: fromUtil.UtilState;
}

export const reducers: ActionReducerMap<AppState> = {
	auth: fromAuth.authReducer,
	component: fromComponent.ComponentReducer,
	loading: fromLoading.loadingReducer,
	message: fromMessage.messageReducer,
	util: fromUtil.utilReducer,
};
