import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { Subject,Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError } from 'rxjs/operators';
import * as AUTH from '../../view/shared/store/auth/auth.actions';
import * as fromRoot from '../../app.reducer';
import { environment } from '../../../environments/environment';
import { HandleErrosService } from './handleErros.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  urlService: string = undefined;
  api_ref: string = environment.API_URL;
  subject = new Subject<any>();

  private controlSubject = new Subject<boolean>();
	control$: Observable<boolean> = this.controlSubject.asObservable();

  tokenDecode: any;

  constructor(
    public httpClient: HttpClient,
    private store: Store<fromRoot.AppState>,
    public handleErrosService: HandleErrosService,
    private router: Router,
  ) {
  }

  //define a url
  public tipoUrl(url) {
    this.urlService = url;
  }

  // responsável por fazer o login, por meio de requisição HTPP
  public post(item: any) {
    return this.httpClient.post<any>(`${this.urlService}`, item, { observe: 'response' })
      .pipe(
        catchError(this.handleErrosService.handleError)
      );
  }

  // responsável por setar a sessão
  setSession(authResult) {
    const tokenInfo = authResult;
    this.tokenDecode = jwt_decode(tokenInfo);
    
    localStorage.setItem('@pesquisa:token', tokenInfo);
    localStorage.setItem('user', this.tokenDecode.user);
    localStorage.setItem('@pesquisa:access_type', this.tokenDecode.access_type);
    localStorage.setItem('userName', this.tokenDecode.name);
  }

  // responsável por realizar logout
  logout() {
    localStorage.removeItem('@pesquisa:token');
    localStorage.removeItem('user');
    localStorage.removeItem('language');
    localStorage.removeItem('@pesquisa:access_type');
    localStorage.removeItem('userName');


    this.clearUserData();
		this.unsubscribes();	
		this.router.navigateByUrl('/');
  }

  clearUserData() {
		localStorage.removeItem('@pesquisa:token');
		// this.store.dispatch(MESSAGE.RemoveMessage());
		this.store.dispatch(AUTH.RemoveUserData());
	}

  // responsável por verificar se o token é diferente de null
  isLoggedIn() {
    return (localStorage.getItem('@pesquisa:token') !== null);
  }

	tokenGenerator(token:string): Observable<any>{	    
		return this.httpClient.post<any>(`${this.api_ref}/usuario/autenticacao`, {credential:token})
	}

	tokenRefresh(token: string): Observable<any>{
		return this.httpClient.post<any>(`${this.api_ref}/auth/refresh`, {token:token})
	}
	
	userDataListener() {
		const token = localStorage.getItem('@pesquisa:token');	
		if(!localStorage.getItem('@pesquisa:config')){
			localStorage.setItem('@pesquisa:config', JSON.stringify({"theme":"light","scale":14,"inputStyle":"outlined","ripple":true}));
		}
		 if (token) {
			this.store.dispatch(AUTH.SetUser({ user: token}));
		} else {
			this.logout()
		 }
	}

	unsubscribes() {
		this.controlSubject.next(false);
		this.controlSubject.complete();
	}

  // responsável por verificar se entraram na ferramente sem realizar o login
  isLoggedOut() {
    return !this.isLoggedIn();
  }

}
