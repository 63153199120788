
import { Store } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import * as XLSX from 'xlsx';
import * as fromApp from '../../app.reducer';
import * as crypto from 'crypto-js';
import * as LOADING from '../../view/shared/store/loading/loading.actions';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})

export class CommonServices {

    constructor(private store: Store<fromApp.AppState>) {}
    //Datas
    minDate: string;
    maxDate: string;

    //função que formata o texto para o padrão Title Case
    titleCase(string) {
        if ((string === null) || (string === ''))
            return false;
        else
            string = string.toString();
        return string.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    //função que formata o texto para apenas o primeiro nome
    FirstName(string) {
        if ((string === null) || (string === ''))
            return false;
        else
            string = string.toString();
            const myArray = string.split(" ");
            let name = myArray[0];
        return name.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    //função que transforma as informações da tabela para o formato xls
    public exportExcel(jsonData: any[], fileName: string): void {
        const worksheet = XLSX.utils.json_to_sheet(jsonData);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveExcelFile(excelBuffer, fileName);
    }

    //função que salva as informações da tabela para o formato xls
    private saveExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        let date = new Date();
        let format = "  " + date.getDate() + " - " + date.getHours() + "_" + date.getMinutes()
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + format + EXCEL_EXTENSION);
    }

    //função que transforma a div referenciada em pdf
    convertToPDF(elementRef, name) {
        let data = document.getElementById(elementRef);

        html2canvas(data)?.then(canvas => {
            let docWidth = 208;
            let docHeight = canvas.height * docWidth / canvas.width;

            const contentDataURL = canvas.toDataURL('image/png')
            let doc = new jsPDF('p', 'mm', 'a4');
            let position = 0;
            doc.addImage(contentDataURL, 'PNG', 0, position, docWidth, docHeight)
            doc.save(name + 'pdf');
        });
    }

    //função que inicia as validações de datas
    initDate() {
        this.getDateUpdate();
    }

    //função que define a data inicial e final
    getDateUpdate() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        let hour = String(today.getHours()).padStart(2, '0');;
        let minute = String(today.getMinutes()).padStart(2, '0');;
        let formatedHour = String(hour) + ":" + String(minute);

        this.minDate = yyyy + '-' + mm + '-' + dd;
        this.maxDate = yyyy + '-' + mm + '-' + (Number(dd) + 5) + 'T' + formatedHour;
    }

    //função que define a data inicial e final
    formatDateddMMyyyy(data: Date) {
        data = new Date(data);
        var dia = data.getDate().toString().padStart(2, '0');
        var mes = (data.getMonth() + 1).toString().padStart(2, '0');
        var ano = data.getFullYear();
        return dia + "/" + mes + "/" + ano;
    }

    //função que retorna estádos do brasil
    estadosBrasil() {
        return [
            { value: 'AC', label: 'Acre - AC' },
            { value: 'AL', label: 'Alagoas - AL' },
            { value: 'AP', label: 'Amapá - AP' },
            { value: 'AM', label: 'Amazonas - AM' },
            { value: 'BA', label: 'Bahia - BA' },
            { value: 'CE', label: 'Ceará - CE' },
            { value: 'DF', label: 'Distrito Federal - DF' },
            { value: 'ES', label: 'Espirito Santo - ES' },
            { value: 'GO', label: 'Goiás - GO' },
            { value: 'MA', label: 'Maranhão - MA' },
            { value: 'MS', label: 'Mato Grosso do Sul - MS' },
            { value: 'MT', label: 'Mato Grosso - MT' },
            { value: 'MG', label: 'Minas Gerais - MG' },
            { value: 'PA', label: 'Pará - PA' },
            { value: 'PB', label: 'Paraíba - PB' },
            { value: 'PR', label: 'Paraná - PR' },
            { value: 'PE', label: 'Pernambuco - PE' },
            { value: 'PI', label: 'Piauí - PI' },
            { value: 'RJ', label: 'Rio de Janeiro - RJ' },
            { value: 'RN', label: 'Rio Grande do Norte - RN' },
            { value: 'RS', label: 'Rio Grande do Sul - RS' },
            { value: 'RO', label: 'Rondônia - RO' },
            { value: 'RR', label: 'Roraima - RR' },
            { value: 'SC', label: 'Santa Catarina - SC' },
            { value: 'SP', label: 'São Paulo - SP' },
            { value: 'SE', label: 'Sergipe - SE' },
            { value: 'TO', label: 'Tocantins - TO' }
        ];
    }
//* Função que formata o objeto para o padrão Base64
convertToBase64(item) {
    return btoa(JSON.stringify(item));
}

encrypt(val:any): string{
    return crypto.AES.encrypt(val, 'zxc#fdsaFDAwsj;6').toString();
}

decrypt(val:any): string{
    var bytes  = crypto.AES.decrypt(val, 'zxc#fdsaFDAwsj;6');
    return bytes.toString(crypto.enc.Utf8);
}

getTheme(): string{
return localStorage.getItem('@comissao:config')?
    JSON.parse(localStorage.getItem('@comissao:config')).theme:'light'
}

numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}

loading(status: boolean){
    if(status){
        this.store.dispatch(LOADING.StartLoading());
    } else {
        this.store.dispatch(LOADING.StopLoading());
    }
}


translateArray(array: any[]): any[]{
    let arrayFinished: any[]=[]
    array.forEach(element => {			          
        arrayFinished.push({name: element.name, code: element.id })
    });
    return arrayFinished
}
}

