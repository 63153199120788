import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GenericService } from 'src/app/controller/services/generic.service';
import { MsgService } from 'src/app/controller/services/msg.service';
import { Mensagens } from '../../shared/components/mensagens-ptbr';
import { CommonServices } from 'src/app/controller/services/common.service';


type user = {
  [key: string]: any;
};

@Component({
  selector: 'app-modal-admin',
  templateUrl: './modal-admin.component.html',
  styleUrls: ['./modal-admin.component.scss']
})

export class ModalAdminComponent implements OnInit {

  AdmForm: FormGroup;
 
  accessTypes:string[] = [
    "administrador",
    "recrutador"
  ]

  display: boolean;
  modalAction: string;

  constructor(
    private genericService: GenericService,
    private msgService: MsgService,
    private commonServices: CommonServices,


  ) { }

  ngOnInit(): void {
    this.formComponent()
  }

    //função para limpar validadores do formulário
    clearValidatorsForm() {
      this.AdmForm.controls.user.clearValidators();
      this.AdmForm.controls.name.clearValidators();
      this.AdmForm.controls.access_type.clearValidators();
      this.AdmForm.updateValueAndValidity();
    }

  Confirm(){
    switch (this.modalAction) {
      case "add":
        
        let user = {
          access_type:(this.AdmForm.controls.access_type.value).toLowerCase(),
          language :"pt-br",
          name:this.AdmForm.controls.name.value,
          user:this.AdmForm.controls.user.value
        }


        this.genericService.postAdm("usuario/cadastra",user).subscribe(
          (resp) => {
            this.msgService.getMessage('success', Mensagens.CadastroSummary, Mensagens.CadastroDetail);
            this.display = false;
            this.clearValidatorsForm();
            this.AdmForm.reset();
          }, error => {     
              this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.TokenErrorDetail);
              setTimeout(() => {
                this.AdmForm.controls.user.clearValidators();
              }, 2000);
          }
        );
        break;

      case "edit":
        let useredit = {
          access_type:(this.AdmForm.controls.access_type.value).toLowerCase(),
          user:this.AdmForm.controls.user.value
        }


        this.genericService.putAdm("usuario",useredit).subscribe(
          (resp) => {
            this.msgService.getMessage('success', Mensagens.CadastroSummary, Mensagens.CadastroDetail);
            this.display = false;
            this.clearValidatorsForm();
            this.AdmForm.reset();
          }, error => {     
              this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.TokenErrorDetail);
              setTimeout(() => {
                this.AdmForm.controls.user.clearValidators();
              }, 2000);
          }
        );
      break;

      case "delete":
        this.genericService.deleteAdm("usuario/delete",this.AdmForm.controls.user.value).subscribe(
          (resp) => {
            this.msgService.getMessage('success', Mensagens.CadastroSummary, Mensagens.CadastroDetail);
            this.display = false;
            this.clearValidatorsForm();
            this.AdmForm.reset();
          }, error => {     
              this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.TokenErrorDetail);
              setTimeout(() => {
                this.AdmForm.controls.user.clearValidators();
              }, 2000);
          }
        );
      break;
    }
  }

  formComponent() {
    this.AdmForm = new FormGroup({
      user: new FormControl((''), [Validators.required]),
      name: new FormControl(('')),
      access_type: new FormControl(('')),
    });
  }


searchname(){
 console.log( !(this.modalAction === 'edit'));
  var user: user;
  var name = 'usuario/situacao/'+this.AdmForm.controls.user.value;
  this.genericService.getGeneric(name)
  .subscribe(
    (resp) => {
      user = resp.body;
      this.AdmForm.controls.name.setValue(user.nome);
      
    }, error => {     
        this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.TokenErrorDetail);
        setTimeout(() => {
          this.AdmForm.controls.user.clearValidators();
        }, 2000);
    }
  );
}

//função que seta os parâmetros para adição
setFormAdd() {  
  this.display = true;
  this.modalAction = 'add';
  this.clearValidatorsForm();
  this.AdmForm.reset();
}

//função que seta os parâmetros para edição
setFormEdit(item) {
  this.AdmForm.controls.user.setValue(item.user);
  this.AdmForm.controls.name.setValue(this.commonServices.titleCase(item.name));
  this.AdmForm.controls.access_type.setValue(item.access_type);

  this.display = true;

  this.modalAction = 'edit';

  this.clearValidatorsForm();
}

  //função que seta os parâmetros para remoção
  setFormRemove(item) {
    
    this.AdmForm.controls.user.setValue(item.user);
    this.AdmForm.controls.name.setValue(this.commonServices.titleCase(item.name));
    this.modalAction = 'delete';
    this.display = true;
  }
}
