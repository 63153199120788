import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})

export class MsgService {

    constructor(
        private messageService: MessageService,
    ) {
    }

    //função que retorna a mensagem
    getMessage(severity, summary, detail) {
        this.messageService.clear();
        this.messageService.add({
            severity: severity,
            summary: summary,
            detail: detail
        });
    }

}