export enum Mensagens {
    ConnectionErrorSummary = 'Erro de comunicação.',
    ConnectionErrorDetail = 'Não foi possível contactar o servidor. Tente novamente em instantes.',

    ConnectionLoginSummary = 'Erro ao acessar',
    ConnectionLoginDetail = 'Usuário ou senha inválido.',

    LoginErrorDetail = 'Usuário não possui permissão ou não está cadastrado.',

    TokenErrorDetail = 'Token expirado ou inválido, tente novamente em instantes.',

    UnauthorizedSummary = 'Não autorizado.',
    UnauthorizedDetail = 'Você não tem autorização para realizar essa operação. Contate o administrador do sistema.',

    CadastroSummary = 'Cadastro realizado.',
    CadastroDetail = 'O cadastro foi realizado com sucesso.',
    CadastroErroSummary = 'Erro ao cadastrar.',
    CadastroErroDetail = 'O cadastro não foi realizado, verifique suas informações.',
    
    CadastroAtentionDetail = 'Alguns itens não foram cadastrados.',

    AtualizarSummary = 'Atualização realizada.',
    AtualizarDetail = 'A atualização foi realizada com sucesso.',
    AtualizarErroSummary = 'Erro ao atualizar.',
    AtualizarErroDetail = 'A atualização não foi realizada, verifique suas informações.',

    ExcluirSummary = 'Exclusão realizada.',
    ExcluirDetail = 'A exclusão foi realizada com sucesso.',
    ExcluirErroSummary = 'Erro ao excluir.',
    ExcluirErroDetail = 'A exclusão não foi realizada, verifique suas informações.',

    ConsultaSummary = 'Consulta realizada.',
    ConsultaDetail = 'A consulta foi realizada com sucesso.',
    ConsultaErroSummary = 'Erro ao consultar.',
    ConsultaErroDetail = 'A consulta não foi realizada, verifique suas informações.',

    AtencaoSummary = 'Atenção.',
    ItemCadastroErrorDetail = 'Item já adicionado',
    TamanhoCadastroErrorDetail = 'Tamanho ou formato inválido',
}
