import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CadastroComponent } from './view/pesquisa-candidato/cadastro.component';
import { AzureComponent } from './view/login/azure/azure.component';
import { RedirectComponent } from './view/login/redirect/redirect.component';

/*  Rotas da Aplicação */
const rotas: Routes = [	{ path: '', component: AzureComponent },
	{ path: 'azure', component: AzureComponent },
	{ path: 'redirect/:token', component: RedirectComponent },
	{ path: 'login', component: AzureComponent },
  { path: 'pesquisa', component: CadastroComponent },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(rotas, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ],
})

export class AppRoutingModule { }
