import { createAction, props } from '@ngrx/store';

export enum MessageActions {
	ADD_ACTION = '[MESSAGE] Add action',
	REMOVE_ACTION = '[MESSAGE] Remove action',
}

export const ActionComponent = createAction(
	MessageActions.ADD_ACTION,
	props<{
		component: string;
		action: any;
		parameter: any[]
	}>(),
);

export const AddAction = createAction(
	MessageActions.ADD_ACTION,
	props<{action: string;}>(),
);

export const RemoveAction = createAction(MessageActions.REMOVE_ACTION);
