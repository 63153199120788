import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { CommonServices } from './controller/services/common.service';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { AuthInterceptor } from './view/auth/auth.interceptor';
import { ErrorInterceptor } from './view/auth/error.interceptor';
import { CadastroModule } from './view/pesquisa-candidato/cadastro.module';
import { PrimeNgModule } from './view/shared/components/PrimeNg.module';
import { SharedCommonModule } from './view/shared/sharedCommon.module';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './app.reducer';
import { environment } from 'src/environments/environment';


registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedCommonModule,
    CadastroModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production,
		}),
		StoreRouterConnectingModule.forRoot({
			stateKey: 'router',
			routerState: RouterState.Minimal,
		}),
    /* ---INTERFACE--- */
    PrimeNgModule,
    TranslocoRootModule,
    //Loading
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    
  ],
  providers: [CommonServices, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
