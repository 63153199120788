import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../environments/environment';

type Cards = { icon: string; title: string; subtitle: string; background: string; }

@Component({
  selector: 'app-azure',
  templateUrl: './azure.component.html',
  styleUrls: ['./azure.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AzureComponent implements OnInit {

  constructor() { }

  cards: Cards[] = [
    { icon: "../../../../assets/img/button icon.png", title: "Natcorp", subtitle:  "Saiba se um candidato já fez parte do quadro da Stefanini e se há algum comentário sobre sua passagem anterior.", background: "bg-blue-400" },
    { icon: "../../../../assets/img/button icon escavador.png", title:  "Escavador", subtitle:  "Através do Escavador, é possível consultar processos jurídicos que um candidato esteja envolvido.", background: "bg-blue-500" },
    { icon: "../../../../assets/img/button icon boavista.png", title:  "Boa Vista SCPC", subtitle:  "Consulte a situação do CPF dos colaboradores, analise sua regularidade para contratar com segurança.", background: "bg-blue-400" }
  ]

  ngOnInit(): void {
    if(localStorage.getItem('@comissao:token')||!localStorage.getItem('@pesquisa:azure')){
      window.open(`${environment.AZURE_REDIRECT}${environment.AZURE_PROJECT}/${environment.AZURE_ENVIRONMENT}`, "_self")
    }
  }


  login(){
    window.open(`${environment.AZURE_REDIRECT}${environment.AZURE_PROJECT}/${environment.AZURE_ENVIRONMENT}`, "_self")
  }
}
