import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class LoadingSpinnerService {

    private count = 0;
    private loading$ = new BehaviorSubject<string>('');

    constructor() { }

    // função que escuta a atualização do carregamento
    getLoadingObservable(): Observable<string> {
        return this.loading$.asObservable();
    }

    // função que inicia o carregamento
    requestStart() {
        if (++this.count === 1) {
            this.loading$.next('start');
        }
    }

    // função que finaliza o carregamento
    requestEnd() {
        if (this.count === 0 || --this.count === 0) {
            this.loading$.next('stop');
        }
    }

    // função que reseta o carregamento
    resetLoadin() {
        this.count = 0;
        this.loading$.next('stop');
    }

}