import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CommonServices } from 'src/app/controller/services/common.service';
import { GenericService } from 'src/app/controller/services/generic.service';
import { MsgService } from 'src/app/controller/services/msg.service';
import { Mensagens } from '../../shared/components/mensagens-ptbr';

@Component({
  selector: 'app-cadastro-detalhe',
  templateUrl: './cadastro-detalhe.component.html',
  styleUrls: ['./cadastro-detalhe.component.scss']
})
export class CadastroDetalheComponent implements OnInit {

  //comunicação entre componetes
  @Input() item: Array<any>;
  @Output() eventOutput = new EventEmitter;

  //formulário e elementos
  cadastroForm: FormGroup;
  itemId: string;
  itemNome: string;
  itemCPF: string;
  language: string;
  email: string;
  elementEdit: any;

  //booleans
  displayAdicionar: boolean;
  displayAtencao: boolean;
  displayExcluir: boolean;
  isEditar: boolean;
  isComplete: boolean;
  showDiretor: boolean;

  //Datas
  maxDate: string;

  //usuario
  isLoadingButton: boolean;

  //selects
  perfis: SelectItem[];
  itensRegistrados: any;
  diretores: SelectItem[];
  cpfList: Array<{ cpf: string, rg: string }> = [];

  //autocomplete
  estados: { label: string; value: string }[] = [];

  //inscrição
  subscription: Subscription = new Subscription();

  constructor(
    public translateService: TranslocoService,
    private genericService: GenericService,
    private msgService: MsgService,
    private commonServices: CommonServices,
  ) { }

  //função que inicia o componente
  ngOnInit() {
    this.formComponent();
    this.getActiveLanguage();
    this.getDate();
  }

  //função que define o formulário
  formComponent() {
    this.cadastroForm = new FormGroup({
      cpf: new FormControl((''), [Validators.required]),
      rg: new FormControl(('')),
      estado: new FormControl(('')),
      cidade: new FormControl(('')),
      dataNascimento: new FormControl(('')),
      nomeMae: new FormControl(('')),
    });
  }

  //função que retorna o idioma ativo
  getActiveLanguage() {
    this.language = this.translateService.getActiveLang();
  }

  //função que define valores iniciais de datas
  getDate() {
    this.commonServices.initDate();
    this.maxDate = this.commonServices.minDate;
    this.getBrasilStates();
  }

  //função que valida a data de aniversário
  validaData() {
    let ano = this.cadastroForm.controls.dataNascimento.value.slice(0, 4);
    let anoMax = this.maxDate.slice(0, 4);

    if (Number(ano) > Number(anoMax)) {
      this.cadastroForm.controls.dataNascimento.reset();
    }
  }

  //função que seta os parâmetros para adição
  setFormAdd() {
    this.displayAdicionar = true;
    this.isEditar = false;
    this.cpfList = [];
    this.clearValidatorsForm();
    this.cadastroForm.reset();
  }

  //função que seta os parâmetros para edição
  setFormEdit(item) {    
    this.elementEdit = item;
    this.cadastroForm.controls.cpf.setValue(item.document);
    this.cadastroForm.controls.rg.setValue(item.registry);
    this.itemCPF = item.document;
    this.cpfList = [];

    this.displayAdicionar = true;
    this.isEditar = true;

    this.clearValidatorsForm();

    if (item?.is_incomplete === 1) {
      this.setValidatorsForm();
    } else if (item?.is_incomplete === 0) {
      this.setItensForm();
    }
  }

  //função que popula os itens no formulário
  setItensForm() {
    if (this.elementEdit?.birth_date) {
      let dt_nascimento = this.elementEdit?.birth_date.split('/')[2] + '-' + this.elementEdit?.birth_date.split('/')[1] + '-' + this.elementEdit?.birth_date.split('/')[0];
      this.cadastroForm.controls.dataNascimento.setValue(dt_nascimento);
    }

    this.cadastroForm.controls.estado.reset();
    if (!!this.elementEdit?.state_acronym) {
      let search = this.estados.find(i => {
        return i.value === this.elementEdit.state_acronym;
      });
      this.cadastroForm.controls.estado.setValue(search);
    }

    this.cadastroForm.controls.cidade.setValue(this.elementEdit?.city);
    this.cadastroForm.controls.nomeMae.setValue(this.elementEdit?.mother_name);
  }

  //função que define os validadores do formulário
  setValidatorsForm() {
    this.isComplete = false;
    this.cadastroForm.controls.estado.setValidators(Validators.required);
    this.cadastroForm.controls.cidade.setValidators(Validators.required);
    this.cadastroForm.controls.dataNascimento.setValidators(Validators.required);
    this.cadastroForm.controls.nomeMae.setValidators(Validators.required);
    this.cadastroForm.updateValueAndValidity();

    this.setItensForm();
  }

  //função para limpar validadores do formulário
  clearValidatorsForm() {
    this.isComplete = true;
    this.cadastroForm.controls.estado.clearValidators();
    this.cadastroForm.controls.cidade.clearValidators();
    this.cadastroForm.controls.dataNascimento.clearValidators();
    this.cadastroForm.controls.nomeMae.clearValidators();
    this.cadastroForm.updateValueAndValidity();
  }

  //função que seta os parâmetros para remoção
  setFormRemove(item) {
    this.cadastroForm.controls.cpf.setValue(item.document);
    this.cadastroForm.controls.rg.setValue(item.registry);
    this.itemId = item.name;
    this.itemNome = item.name;
    this.isEditar = false;
    this.displayExcluir = true;
  }

  //função que cancela a interação e limpa o furmulário
  cancelar() {
    this.displayAdicionar = false;
    this.cadastroForm.reset();
  }

  //função para adicionar o elemento selecionado
  salvar() {
    this.isLoadingButton = true;

    this.genericService.tipoUrl('candidato/cadastro', this.cpfList);

    let candidatoCadastroSub$ = this.genericService.post()
      .subscribe(
        (resp) => {
          if (resp[0]) {
            this.itensRegistrados = resp;
            this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.CadastroAtentionDetail);
            this.displayAdicionar = false;
            this.displayAtencao = true;
            this.isLoadingButton = false;

          } else {
            this.itensRegistrados = [];
            this.msgService.getMessage('success', Mensagens.CadastroSummary, Mensagens.CadastroDetail);
            this.displayAdicionar = false;
            this.displayAtencao = false;
            this.isLoadingButton = false;
          }
          this.eventOutput.emit();
          this.cadastroForm.reset();
        }, error => {
          if (error.status === 423) {
            this.itensRegistrados = error.error;
            this.msgService.getMessage('error', Mensagens.AtencaoSummary, Mensagens.CadastroAtentionDetail);
            this.displayAtencao = true;
            this.isLoadingButton = false;
          } else {
            this.itensRegistrados = [];
            this.isLoadingButton = false;
            this.msgService.getMessage('error', Mensagens.CadastroErroSummary, Mensagens.CadastroErroDetail);
          }
          this.cadastroForm.reset();
        }
      );
    this.subscription.add(candidatoCadastroSub$);
  }

  //função para alterar o elemento selecionado
  editar() {
    this.isLoadingButton = true;

    let candidatoAtualizacaoSub$ = this.genericService.tipoUrl('candidato/atualizar', this.cadastroForm.value);

    this.genericService.put()
      .subscribe(
        (resp) => {
          this.msgService.getMessage('success', Mensagens.AtualizarSummary, Mensagens.AtualizarDetail);
          this.eventOutput.emit();
          this.cadastroForm.reset();
          this.displayAdicionar = false;

          this.isLoadingButton = false;
        }, error => {
          this.isLoadingButton = false;
          this.msgService.getMessage('error', Mensagens.AtualizarErroSummary, Mensagens.AtualizarErroDetail);
        }
      );
    this.subscription.add(candidatoAtualizacaoSub$);
  }

  //função para remover o elemento selecionado
  excluir() {

    this.genericService.tipoUrl('candidato/delete', this.cadastroForm.value);

    let candidatoExclusaoSub$ = this.genericService.delete()
      .subscribe(
        (resp) => {
          this.msgService.getMessage('success', Mensagens.ExcluirSummary, Mensagens.ExcluirDetail);
          this.eventOutput.emit();
          this.displayExcluir = false;
        }, error => {
          this.msgService.getMessage('error', Mensagens.ExcluirErroSummary, Mensagens.ExcluirErroDetail);
        }
      );
    this.subscription.add(candidatoExclusaoSub$);
  }

  //função que restringe o compo apenas a números
  onlyNumber(event): boolean {
    return this.commonServices.numberOnly(event);
  }

  // função que adiciona cpfs a lista
  addCPFList() {

    let cpfFormated;

    if (this.cadastroForm.controls.cpf.value?.length === 14) {
      this.cadastroForm.controls.cpf.setValue(this.cadastroForm.controls.cpf.value.replace(".", "").replace(".", "").replace(".", "").replace("-", ""));
      if (this.cadastroForm.controls.cpf.value?.length === 14) {
        this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.TamanhoCadastroErrorDetail);
        this.cadastroForm.controls.cpf.reset();
      }
    }

    if (this.cadastroForm.controls.cpf.value?.length === 11) {
      cpfFormated = this.cadastroForm.controls.cpf.value;
    }

    cpfFormated = this.cadastroForm.controls.cpf.value.replace(".", "").replace(".", "").replace(".", "").replace("-", "");
    if (cpfFormated?.length === 11) {

      if (this.cpfList.includes(this.cadastroForm.value.cpf)) {
        this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.ItemCadastroErrorDetail);
        this.cadastroForm.controls.cpf.reset();
      } else {
        this.cpfList.push({
          cpf: this.cadastroForm.value.cpf.replace(".", "").replace(".", "").replace(".", "").replace("-", ""),
          rg: this.cadastroForm.value.rg
        });
        this.cadastroForm.controls.cpf.reset();
        this.cadastroForm.controls.rg.reset();
      }
    }
  }

  // função que remove o cpf da listagem
  removeCPF(item) {
    this.cpfList = this.cpfList.filter((i) => {
      return i !== item;
    });
    return this.cpfList;
  }

  //função que cancela a interação
  cancelarExcluir() {
    this.displayExcluir = false;
  }

  //função que retorna para listagem
  voltarLista() {
    this.displayAtencao = false;
  }

  //função que retorna estados do brasil
  getBrasilStates() {
    this.estados = this.commonServices.estadosBrasil();
  }

  //responsável que encerrar conexão de um componente
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
