import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HandleErrosService } from './handleErros.service';
import { CommonServices } from 'src/app/controller/services/common.service';
import { CryptoService } from "src/app/controller/services/crypto.service";

@Injectable({
    providedIn: 'root'
})

export class GenericService {

    urlService: string = undefined;
    api_ref: string = environment.API_URL;
    subject = new Subject<any>();

    constructor(
        public httpClient: HttpClient,
        public handleErrosService: HandleErrosService,
        private commonServices: CommonServices,
        private cryptoService: CryptoService
    ) {
    }

    //define a url
    public tipoUrl(url, objeto) {

        //Conversão do objeto para Base64
        let objBase64 = this.commonServices.convertToBase64(objeto);

        //Encriptação do obejto previamente convertido paraBase64
        let objEncrip = this.cryptoService.encrypt(objBase64);
        
        this.urlService = `${url}/${objEncrip}`;
    }

    //consultar todos os itens
    public get() {
        return this.httpClient.get<any[]>(`${this.api_ref}/${this.urlService}`, { observe: 'response' })
            .pipe(
                catchError(this.handleErrosService.handleError)
            );
    }

    public getGeneric(url,params?) {
        return this.httpClient.get<any>(`${this.api_ref}/${url}`, { observe: 'response',params: {...params} })
            .pipe(
                catchError(this.handleErrosService.handleError)
            );
    }

    //consultar o item por id
    public getById() {
        return this.httpClient.get<any>(`${this.api_ref}/${this.urlService}`, { observe: 'response' })
            .pipe(
                catchError(this.handleErrosService.handleError)
            );
    }

    //cadastrar o item
    public post() {
        return this.httpClient.post<any>(`${this.api_ref}/${this.urlService}`, { observe: 'response' })
            .pipe(
                catchError(this.handleErrosService.handleError)
            );
    }

    //alterar o item
    public put() {
        return this.httpClient.put<any>(`${this.api_ref}/${this.urlService}`, { observe: 'response' })
            .pipe(
                catchError(this.handleErrosService.handleError)
            );
    }

    //remover o item
    public delete() {
        return this.httpClient.delete(`${this.api_ref}/${this.urlService}`, { observe: 'response' })
            .pipe(
                catchError(this.handleErrosService.handleError)
            );
    }

    //cadastrar o item
    public postAdm(url:string, obj) {
        return this.httpClient.post<any>(`${this.api_ref}/${url}`,obj, { observe: 'response' })
            .pipe(
                catchError(this.handleErrosService.handleError)
            );
    }

    //alterar o item
    public putAdm(url:string, obj) {
        return this.httpClient.put<any>(`${this.api_ref}/${url}`,obj, { observe: 'response' })
            .pipe(
                catchError(this.handleErrosService.handleError)
            );
    }

    //remover o item
    public deleteAdm(url:string, id:string) {
        return this.httpClient.delete(`${this.api_ref}/${url}/${id}`, { observe: 'response' })
            .pipe(
                catchError(this.handleErrosService.handleError)
            );
    }


    //função que retorna o caminho do pdf
    public getPDF(url) {
        return this.api_ref + url;
    }

}
