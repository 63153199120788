import { LocationStrategy } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Token } from 'src/app/controller/models/token.model';
import { CryptoService } from 'src/app/controller/services/crypto.service';
import { GenericService } from 'src/app/controller/services/generic.service';
import { LoginService } from 'src/app/controller/services/login.service';
import { MsgService } from 'src/app/controller/services/msg.service';
import { Mensagens } from '../shared/components/mensagens-ptbr';
import { CadastroDetalheComponent } from './cadastro-detalhe/cadastro-detalhe.component';
import { PesquisaDetalheComponent } from './pesquisa-detalhe/pesquisa-detalhe.component';
import { ModalAdminComponent } from './modal-admin/modal-admin.component';
import { LazyLoadEvent } from 'primeng/api';

type params = {
  limit?: number;
  offset?: number;
  name?: string;
  registry?: string;
  document?: string;
  date?: string;
  user?:string;
  access_type?:string;
}
@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})



export class CadastroComponent implements OnInit {

  //comunicação entre componetes
  @ViewChild(CadastroDetalheComponent) componetChild;
  @ViewChild(PesquisaDetalheComponent) componetChild2;
  @ViewChild(ModalAdminComponent) componetChild3;

  //modelo
  itens: any[] = [];
  itensCount: number = 0;
  admTable: any[] = [];
  admCount: number;
  tokenStorage: any;
  tokenObj: Token;
  tokenFormated: any;
  urlOrigem: any;
  urlFormated: any;
  access_type:any = localStorage.getItem("@pesquisa:access_type");
  custFilterRg:any;
  custFilterCpf:any;

  //filtro
  name:string;
  date:Date;
  registry:string;
  document:string;



  //tabela
  cols: any[];
  colsAdm: any[];

  //booleans
  showDetail: boolean = true;
  isError: boolean;

  //inscrição
  subscription: Subscription = new Subscription();
  subscriptionAdm: Subscription = new Subscription();


  constructor(
    private genericService: GenericService,
    private msgService: MsgService,
    private location: LocationStrategy,
    private loginService: LoginService,
    private cryptoService: CryptoService,
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  //função que inicia o componente
  ngOnInit() {
    this.hasToken();
    this.setParamsTable();
  }

  //função que define os parâmetros para a criação da tabela
  setParamsTable() {
    this.cols = [
      { field: 'name', header: 'Nome', width: '30%', align: 'left' },
      { field: 'birth_date', header: 'Nascimento', width: '20%', align: 'left' },
      { field: 'document',type:'number', header: 'CPF', width: '15%', align: 'left' },
      { field: 'registry', header: 'RG', width: '20%', align: 'left' },
    ];
    this.colsAdm = [
      { field: 'name', header: 'Nome', width: '30%', align: 'left' },
      { field: 'user', header: 'Usuário de Rede', width: '20%', align: 'left' },
      { field: 'access_type', header: 'Tipo de Acesso', width: '15%', align: 'left' },
    ];
  }

  onPasteCpf(event){
    let pastedText =event;
    this.custFilterCpf = pastedText.replace(/[^0-9]/g,'');
    return this.custFilterCpf;
  }

  onPasteRg(event){
    let pastedText =event;
    this.custFilterRg = pastedText.replace(/[^0-9]/g,'');
    return this.custFilterRg;
  }

  test_fn(e){    
    console.log(e);

    if (e.keyCode == 46 ||e.keyCode == 45 ){
      console.log('entro');
      
      e.preventDefault(); // not allowed to type .
    } 
  }

  //função que consulta as informações cadastradas
  getTableInfo(event?: LazyLoadEvent) {    
    //objeto contendo as informações necessárias para o back-end fazer a filtragem das vagas
    let userInfo = {
      token: localStorage.getItem("@pesquisa:token"),
    }
    const params = this.paramsBuilder(true, event);
    this.genericService.tipoUrl('init/candidato', userInfo);
    let initCandidatoSub$ = this.genericService.getGeneric('init/candidato',params)
      .subscribe(
        (resp) => {
          if (resp.body === undefined) {
            this.itens = [];
          } else {
            this.itens = resp.body.result;
            this.itensCount =  resp.body.count;
            this.isError = false;
          }
        }, error => {
          this.isError = true;
          if (error.status === 401) {
            this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.TokenErrorDetail);
            setTimeout(() => {
              this.logout()
            }, 2000);
          }

          else if (error.status === 404) {
            this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.ConnectionErrorSummary);
          }

          else if (error.status === 500) {
            this.msgService.getMessage('warn', Mensagens.ConnectionErrorSummary, Mensagens.ConnectionErrorDetail);
          } else {
            this.msgService.getMessage('warn', Mensagens.ConnectionErrorSummary, Mensagens.ConsultaErroDetail);
          }
        }
      );
    this.subscription.add(initCandidatoSub$);
  }

private paramsBuilder(
  isLazyLoad: boolean,
  event?: any
) {
  
  let params: params = {};

  if (isLazyLoad) {
    params.limit = event?.rows ?? 10;
    params.offset = event?.first?? 0;
  }
  if (event?.filters.name) {
    params.name = event?.filters.name.value;
  }
  if (event?.filters.registry) {
    params.registry = event?.filters.registry.value;
  }
  if (event?.filters.document) {
    params.document = event?.filters.document.value;
  }
  if (event?.filters.birth_date) {
    params.date = event?.filters.birth_date.value;
  }
  if (event?.filters.user) {
    params.user = event?.filters.user.value;
  }
  if (event?.filters.access_type) {
    params.access_type = event?.filters.access_type.value;
  }
  return params;
}

   //função que consulta as informações de usuários cadastrados
   getAdminInfo(event?: LazyLoadEvent) {
    const params = this.paramsBuilder(true, event);
    let initAdminSub$ = this.genericService.getGeneric('usuarios',params)
      .subscribe(
        (resp) => {
          if (resp.body === undefined) {
            this.admTable = [];
          } else {
            this.admTable = resp.body.result;
            this.admCount =  resp.body.count;
            this.isError = false;
          }
        }, error => {
          this.isError = true;
          if (error.status === 401) {
            this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.TokenErrorDetail);
            setTimeout(() => {
              this.logout()
            }, 2000);
          }

          else if (error.status === 404) {
            this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.ConnectionErrorSummary);
          }

          else if (error.status === 500) {
            this.msgService.getMessage('warn', Mensagens.ConnectionErrorSummary, Mensagens.ConnectionErrorDetail);
          } else {
            this.msgService.getMessage('warn', Mensagens.ConnectionErrorSummary, Mensagens.ConsultaErroDetail);
          }
        }
      );
    this.subscriptionAdm.add(initAdminSub$);
  }

  //função que define os parametros de adição para o elemento filho
  getAdicionar() {
    this.componetChild.setFormAdd();
  }

  //função que define os parametros de edição para o elemento filho
  getEditar(event, item) {
    this.componetChild.setFormEdit(item);
  }

  //função que define os parametros de exclusão para o elemento filho
  getExcluir(event, item) {
    this.componetChild.setFormRemove(item);
  }

  getAdicionarAdm() {   
    this.componetChild3.setFormAdd();
  }

  //função que define os parametros de edição para o elemento filho
  getEditarAdm(event, item) {
    this.componetChild3.setFormEdit(item);
  }

  //função que define os parametros de exclusão para o elemento filho
  getExcluirAdm(event, item) {
    this.componetChild3.setFormRemove(item);
  }

  //função que seleciona o item para detalhamento
  selectItem(item) {
    this.showDetail = false;
    this.componetChild2.isDetail = true;
    this.componetChild2.setFormDetail(item);
  }

  //função que retorna a visão principal
  visaoInicial() {
    this.showDetail = true;
    this.componetChild2.isDetail = false;
    this.componetChild2.isConsulta = false;
  }

  //função que verifica a existência do token
  hasToken() {
    if (!localStorage.getItem('@pesquisa:token')) {
      this.setTokenInfo();
    } else {
      this.getInfoToken();
    }
  }

  //função que valida se existe token de acesso
  setTokenInfo() {
    this.urlOrigem = window.location.href;
    this.urlFormated = this.urlOrigem.toString().split("pesquisa");
    this.tokenFormated = this.urlFormated[1];

    this.tokenStorage = this.cryptoService.decrypt(this.tokenFormated);
    this.tokenObj = JSON.parse(atob(this.tokenStorage));

    localStorage.setItem('urlOrigem', this.tokenObj.url);
    localStorage.setItem('language', this.tokenObj.language);
    this.setSession(this.tokenObj.info);
  }

  //função que valida se existe token de acesso
  getInfoToken() {
    this.setSession(localStorage.getItem('@pesquisa:token'));
  }

  // responsável por setar a sessão
  setSession(item) {
    this.loginService.setSession(item);
    // this.getTableInfo();
    this.getAdminInfo();
  }

  //função que realiza o logout da plataforma
  logout() {
    this.loginService.logout();
    // location.href = localStorage.getItem('urlOrigem');
  }

  //responsável que encerrar conexão de um componente
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionAdm.unsubscribe();

  }

}
