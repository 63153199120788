import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItem } from 'primeng/api';
import { CommonServices } from 'src/app/controller/services/common.service';
import { LoginService } from 'src/app/controller/services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  items: MenuItem[];
  language: string;
  sideBar: boolean;
  user: string;

  constructor(
    private commonService:CommonServices,
    private router: Router,
    private loginService: LoginService,
    public translateService: TranslocoService,
  ) { }

  ngOnInit() {
    let name = localStorage.getItem('userName');
    this.setName(name);
  }

  //função que apresenta as opções de seleção de idioma
  setectLanguage() {
    this.sideBar = true;
  }

  //função que retorna o idioma ativo
  getActiveLanguage() {
    this.language = this.translateService.getActiveLang();
  }

  setName(name:string){
    let janela = window.location.href;
    let janela2 = janela.toString().split("#/");
    let teste = janela2[1];
    if(teste ===''){
      this.user= undefined;
    }else{
      this.user = this.commonService.FirstName(name);
    }
  }
  //função que realiza o logout da plataforma
  logout() {
    this.loginService.logout();
    // location.href = localStorage.getItem('urlOrigem');
  }

}
