import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CPFPipe } from 'src/app/controller/models/cpf.pipe';
import { CommonServices } from 'src/app/controller/services/common.service';
import { TranslocoRootModule } from 'src/app/transloco/transloco-root.module';
import { PrimeNgModule } from '../shared/components/PrimeNg.module';
import { SharedCommonModule } from '../shared/sharedCommon.module';
import { CadastroDetalheComponent } from './cadastro-detalhe/cadastro-detalhe.component';
import { CadastroComponent } from './cadastro.component';
import { CadastroRoutingModule } from './cadastro.routing.module';
import { PesquisaDetalheComponent } from './pesquisa-detalhe/pesquisa-detalhe.component';
import { PesquisaInfoComponent } from './pesquisa-info/pesquisa-info.component';
import { AzureComponent } from '../login/azure/azure.component';
import { ModalAdminComponent } from './modal-admin/modal-admin.component';
import { RedirectComponent } from '../login/redirect/redirect.component';

registerLocaleData(localePt);

@NgModule({
    declarations: [
        ModalAdminComponent,
        AzureComponent,
        RedirectComponent,
        CadastroComponent,
        CadastroDetalheComponent,
        PesquisaDetalheComponent,
        PesquisaInfoComponent,
        CPFPipe
    ],
    imports: [
        CadastroRoutingModule,
        SharedCommonModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        /* ----interface---- */
        PrimeNgModule,
        /* tradução */
        TranslocoRootModule
    ],
    exports: [
        CadastroComponent,
        CadastroDetalheComponent,
        PesquisaInfoComponent
    ],
    providers: [
        CommonServices
    ],
})

export class CadastroModule { }
