import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as LOADING from '../../shared/store/loading/loading.actions';
import { LoginService } from '../../../controller/services/login.service';
import { MsgService } from 'src/app/controller/services/msg.service';
import { Mensagens } from '../../shared/components/mensagens-ptbr';


@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private authService: LoginService,
              private msgService: MsgService,
              private router: Router,
              private store: Store<fromRoot.AppState>) { }

  ngOnInit(): void {
    this.store.dispatch(LOADING.StartLoading());
    let token = this.activatedRoute.snapshot.params['token']
    localStorage.setItem('@pesquisa:azure', token);
    this.authService.tokenGenerator(token).subscribe({
        next: result => {
            this.authService.setSession(result.access_token);
            localStorage.setItem('@pesquisa:token', result.access_token);
            setTimeout(() => {
                this.authService.userDataListener()
                setTimeout(() => {
                    this.store.dispatch(LOADING.StopLoading());                    
                    this.router.navigateByUrl('/pesquisa')
                  }, 200);
            }, 2000);
        },
        error: ((err) => {
          console.log(err);
          
              setTimeout(() => {
                this.msgService.getMessage('warn', Mensagens.AtencaoSummary, Mensagens.TokenErrorDetail);
              }, 2000);
              this.authService.logout();

            this.store.dispatch(LOADING.StopLoading());
            this.router.navigateByUrl('/')            
        })
    })
  }
}
