import { HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { HandleErrosService } from 'src/app/controller/services/handleErros.service';
import { LoginService } from 'src/app/controller/services/login.service';
import { LoadingSpinnerService } from '../shared/loading/loading.service';
import { LoadingService } from './loading.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loadingService: LoadingService,
    public handleErrosService: HandleErrosService,
    public loginService: LoginService,
    private loadingSpinnerService: LoadingSpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
    | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    const token = localStorage.getItem('@pesquisa:token');
    console.log(req.url);
    
    if (!req.url.includes('usuarios') && !req.url.includes('init/candidato')) {
      this.loadingService.setLoading(true);
      this.loadingSpinnerService.requestStart();
    }

    const cloned = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token)
    });

    if (token) {
      return next.handle(cloned)
        .pipe(
          catchError(x => this.manipularErro(x)),
          finalize(() => { this.loadingService.setLoading(false), this.loadingSpinnerService.requestEnd(); })
        );
    } else {
      return next.handle(req).pipe(
        catchError(x => this.autenticacaoError(x)),
        finalize(() => { this.loadingService.setLoading(false), this.loadingSpinnerService.requestEnd(); })
      );
    }
  }

  private manipularErro(erro: HttpErrorResponse): Observable<any> {

    this.loadingSpinnerService.resetLoadin();

    if ((erro.status == 401 || erro.status == 403) && erro.error?.erros?.length > 0) {
      this.loginService.logout();
      return this.handleErrosService.handleError(erro);
    }

    if ((erro.status >= 400 && erro.status <= 499) && erro.error?.erros?.length > 0) {
      return this.handleErrosService.handleError(erro);
    }

    if ((erro.status >= 500 && erro.status <= 599) && erro.error?.erros?.length > 0) {
      return this.handleErrosService.handleError(erro);
    }

    return this.handleErrosService.handleError(erro);
  }

  autenticacaoError(erro: HttpErrorResponse) {
    if (erro.status === 422) {
      return this.handleErrosService.handleErrorLogin(erro);
    } else {
      return this.handleErrosService.handleError(erro);
    }
  }
}
